import React from 'react';
import { Breadcrumb, Layout } from '../../components';
import { CareerOpeningViewProps } from '../../types';

const CareerOpeningView: React.FunctionComponent<CareerOpeningViewProps> = ({
  job,
}) => {
  const jobTitle = getJobTitle();

  function getJobTitle() {
    let title = '';
    title += job.city ? job.city : '';
    title += job.state ? (title !== '' ? ', ' : '') + job.state : '';
    title += job.country ? (title !== '' ? ' - ' : '') + job.country : '';
    return title;
  }

  return (
    <Layout
      title={job.title + ' ' + jobTitle}
      description=""
      banner={{
        src: '/media/careers/careers.jpg',
        subtitle: 'Job Details',
        title: 'Pinkerton Careers',
      }}
      className="articleWithPatternLayout"
    >
      <div className="overview jobs">
        <div className="wrapper">
          <Breadcrumb
            items={[
              {
                label: 'Careers',
                path: '/careers/current-openings',
              },
              {
                label: 'Current Openings',
                path: '#',
              },
            ]}
          />
          <div className="twoColSingle">
            <article className="twoColSingle--main">
              <h2 className="jobs--title">
                {job.title} <br />{' '}
                <span className="jobs--title_sm">{getJobTitle()}</span>
              </h2>
              <div className="jobs--details">
                <p>
                  <br />
                  <strong>
                    170+ Years Strong. Industry Leader. Global Impact.
                  </strong>
                  <br />
                  <br />
                  At Pinkerton, the mission is to protect our clients. To do
                  this, we provide enterprise risk management services and
                  programs specifically designed for each client. Pinkerton
                  employees are one of our most important assets and critical to
                  the delivery of world-class solutions. Bonded together, we
                  share a commitment to integrity, vigilance, and excellence.
                </p>
                <div className="jobs--details-section">
                  <h3 className="jobs--details-section-title">Job Summary:</h3>
                  <div
                    className="jobs--summary"
                    dangerouslySetInnerHTML={{ __html: job.jobsummary }}
                  ></div>
                </div>
                <div className="jobs--details-section">
                  <h3 className="jobs--details-section-title">
                    Essential Functions:
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: job.essentialfunctions,
                    }}
                  ></div>
                </div>
                <div className="jobs--details-section">
                  <h3 className="jobs--details-section-title">
                    Education, Experience, and Certifications:
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: job.educationexperience,
                    }}
                  ></div>
                </div>
                <div className="jobs--details-section">
                  <h3 className="jobs--details-section-title">Competencies:</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: job.competencies }}
                  ></div>
                </div>
                <div className="jobs--details-section">
                  <h3 className="jobs--details-section-title">
                    Working Conditions:
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: job.workingconditions,
                    }}
                  ></div>
                </div>
              </div>
              <p className="eeo-statement">
                Pinkerton is an equal opportunity employer to all applicants and
                positions without regard to race/ethnicity, color, national
                origin, ancestry, sex/gender, gender identity/expression, sexual
                orientation, marital/prenatal status, pregnancy/childbirth or
                related conditions, religion, creed, age, disability, genetic
                information, veteran status, or any protected status by local,
                state, federal or country-specific law.
              </p>
              <div className="jobs--buttons">
                <a
                  className="btn btn_block"
                  href={job.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Quick apply</span>
                </a>
                <a
                  className="btn btn_block"
                  href={job.internalurl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Current employees</span>
                </a>
              </div>
              <p className="note">
                *Current employees include Pinkerton and all Securitas
                divisions.
              </p>
            </article>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CareerOpeningView;
